<template>
  <v-container v-if="getSelectedUser" id="user_view" tag="section" fluid>
    <c-tabs :items="items" />
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { errorNotification } from "@/lib/calendesk-js-library/tools/notification";
import CTabs from "@/components/CTabs/CTabs";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default {
  name: "User",
  components: {
    CTabs,
  },
  mixins: [sharedActions],
  data() {
    return {
      items: [
        {
          tab: "client_data",
          content: () => import("./components/UserFullForm.vue"),
        },
        {
          tab: "user_bookings",
          content: () => import("./components/UserBookings.vue"),
        },
        {
          tab: "user_payments",
          content: () => import("./components/UserPayments.vue"),
        },
        {
          tab: "user_preferences",
          content: () => import("./components/UserPreferences.vue"),
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getSelectedUser: "user/getSelectedUser",
    }),
  },
  mounted() {
    this.setSelectedUser(null);
    this.setSelectedEmployee(null);

    this.setIsSending(true);
    this.getUser(this.$route.params.id)
      .then((response) => {
        this.setSelectedUser(response);
      })
      .catch(() => {
        errorNotification("user_can_not_be_loaded", {}, false);
        this.refreshUsers();
        this.$router.push({ name: "users" });
      })
      .finally(() => {
        this.setIsSending(false);
      });
  },
  methods: {
    ...mapActions({
      getUser: "user/get",
      refreshUsers: "user/refreshUsers",
    }),
    ...mapMutations({
      setSelectedUser: "user/SET_SELECTED_USER",
      setSelectedEmployee: "employee/SET_SELECTED_EMPLOYEE",
    }),
  },
};
</script>
